<template>
  <div>
    <headers />
    <div class="cmpWmV">
      <div class="dIQKtV">
        <div class="dbBJMc">
          <p>基于真实能源和生产设备数据的工业品碳足迹平台，<br/>中小制造业企业数字化专家</p>
        </div>
        <div class="gPaqoi" style="opacity: 0.7">
          <p>千家中小型制造业企业数字化实施,大量工业数据协同，形成精准碳工业品足迹。<br/>
公司拥有完全自主研发的全套软硬件和SaaS服务平台，以人工智能为核心技术，对<br/>制造业中小企业进行快速数字化<br/>
转型。让企业植根与数字土壤之中茁壮成长，助力每个中小型制造业企业成长为参<br/>
天大树。同时通过多种AI和算法协同，应用区块链技术构筑起关键节点数据加密和<br/>确权，形成以真实数据为底层，产业链为依托的工业品碳足迹服务平台。</p>
        </div>
      </div>
    </div>

    <div class="page_content">
      <div id="growup" class="page_1" ref="page_1">
        <div class="content">
          <div class="intro_title">数壤成长</div>
          <div>
            <div id="chart" class="chart"></div>
            <div class="info1" :style="{ top: info1Top }">
              <div class="title">公司创立</div>
              <div class="info">
                创始团队在苏州组建,立志为中小企业提供数字化转型服务。
              </div>
            </div>
            <div class="info2">
              <div class="title">架构搭建</div>
              <div class="info">
                <p>
                  基于能源管理,PLM和MES三种企业常用数字化转型工具进行基础框架搭建,同时完成教育实训产品研发。
                </p>
                <p>对海量中小型执照业企业充分调研,确定通用需求和产品方向。</p>
              </div>
            </div>
            <div class="info3" :style="{ top: info3Top }">
              <div class="title">推广实践</div>
              <div class="info">
                <p>不断进行技术研发投入,对产品进行迭代优化,降低企业使用产品门槛。</p>
                <p>
                  在马鞍山市刃模具行业,淄博铸造行业,潍坊大型装备行业,内蒙古有色金属行业,山西水泥行业等落地验证并得到良好反馈,逐渐形成产品和服务体系标准化。
                </p>
              </div>
            </div>
            <div class="info4">
              <div class="title">技术完善</div>
              <div class="info">
                完成基于IOT技术的多种核心设备数据采集硬件的研发并成功投入使用,同年完成数壤大数据管理平台的研发上线,为企业和行业提供基于人工智能和大数据的服务。
              </div>
            </div>
            <div class="info5" :style="{ top: info5Top }">
              <div class="title">全面展开</div>
              <div class="info">
                千家企业实施拓展，工业数字化全面展开，山东、江苏、浙江、安徽多点开花
              </div>
            </div>
            <div class="info6" :style="{ top: info6Top }">
              <div class="title">立足夯实底蕴</div>
              <div class="info">
                通过VR/AR等技术结合数字孪生，应用区块链技术构筑起关键节点数据加密和确权，全面打造工业元宇宙。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page_2">
        <div id="soil" class="intro_title">数壤——制造业成为参天大树的数字土壤</div>
        <div class="banner">
          <img :src="require('@/assets/image/enterdigiland/banner.png')" alt="" />
        </div>
        <div class="page_cardList">
          <div class="page_card">
            <div class="page_card_title">工业元宇宙与数字孪生的场景应用实现</div>
            <div class="page_card_img">
              <img :src="require('@/assets/image/enterdigiland/card1.png')" alt="" />
            </div>
          </div>
          <div class="page_card">
            <div class="page_card_title">丰富的大数据背景</div>
            <div class="page_card_img">
              <img :src="require('@/assets/image/enterdigiland/card2.png')" alt="" />
            </div>
          </div>
        </div>
        <div class="page_cardList">
          <div class="page_card">
            <div class="page_card_title">深耕的制造业开发</div>
            <div class="page_card_img">
              <img :src="require('@/assets/image/enterdigiland/card3.png')" alt="" />
            </div>
          </div>
          <div class="page_card">
            <div class="page_card_title">资深的专家伙伴</div>
            <div class="page_card_img">
              <img :src="require('@/assets/image/enterdigiland/card4.png')" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="page_3">
        <div id="join" class="intro_title">加入数壤，共同打造工业元宇宙</div>
        <div class="intro_img">
          <img :src="require('@/assets/image/enterdigiland/content2.png')" alt="" />
        </div>
        <div class="intro_join">
          <div>成为合伙人</div>
          <div>成为员工</div>
        </div>
      </div>

      <div class="page_4">
        <div class="intro_img">
          <img :src="require('@/assets/image/enterdigiland/content3.png')" alt="" />
        </div>
        <div class="intro_info">
          汇聚天下英才，植根数字土壤，培养制造业大树，打造工业元宇宙
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from "@/components/head";
import footers from "@/components/foot";
import scrollMixins from "@/utils/scrollMixins";
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
import $ from "jquery";
export default {
  mixins: [scrollMixins,redirect,pcRem],
  components: {
    headers,
    footers,
  },
  data() {
    return {
      info1Top: "",
      info3Top: "",
      info5Top: "",
      info6Top: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      let pWidth = this.$refs.page_1.clientWidth;
      if (pWidth <= 801) {
        this.info1Top = this.px2rem(240);
        this.info3Top = this.px2rem(212);
        this.info5Top = this.px2rem(235);
        this.info6Top = this.px2rem(220);
      } else {
        this.info1Top = this.px2rem(270);
        this.info3Top = this.px2rem(249);
        this.info5Top = this.px2rem(265);
        this.info6Top = this.px2rem(244);
      }
      this.initChart(this.$echarts.init(document.getElementById(`chart`)), pWidth);
    });
  },
  methods: {
    initChart(chart, pWidth) {
      let option = this.initOption(pWidth);
      chart.setOption(option);
      $(window).resize(() => {
        chart.resize();
      });
    },
    initOption(pWidth) {
      let option = {
        grid: {
          x: "-4.2%", //相当于距离左边效果:padding-left
          y: "15%", //相当于距离上边效果:padding-top
          // bottom: "3%",
          width: pWidth + 40,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["2018", "2019", "2020", "2021", "2022", "2023"],
          show: false,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: [50, 568, 100, 420, 165, 325],
            type: "line",
            symbol: "emptyCircle",
            symbolSize: 10,
            label: {
              show: true,
              formatter: "{b}",
            },
            itemStyle: {
              normal: {
                color: "#308971", //改变折线点的颜色
                lineStyle: {
                  color: "#308971", //改变折线颜色
                },
              },
            },
          },
        ],
      };
      return option;
    },
    px2rem($px) {
      return $px / 25 + "rem";
    },
  },
};
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio + rem;
}

.chart {
  width: 100%;
  height: px2rem(220);
}

.cmpWmV {
  display: flex;
  flex-direction: column;
  height: 100vh;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  //   color: white;
  font-weight: 300;
  background-size: cover;
  background-attachment: fixed;
  // background-color: rgb(31, 39, 61);
  // background-color: rgba(0, 0, 0, 0.85);
  background-image: url("~@/assets/image/enterdigiland/cover.png");
  width: 100%;

  .dIQKtV {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    color: white;
    // font-weight: 300;
    background-size: cover;
    background-attachment: fixed;
    width: px2rem(1260);
    background-color: transparent;
    -webkit-box-pack: justify;
    justify-content: space-between;

    .dbBJMc {
      -webkit-box-align: center;
      align-items: center;

      p {
        font-size: px2rem(34);
        font-weight: 500;
        text-align: center;
        height: px2rem(48);
        line-height: px2rem(48);
        color: #fff;
      }
    }

    .gPaqoi {
      width: 100%;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-around;
      margin-top: px2rem(40);
      text-align: center;
      font-size: px2rem(24);
      font-weight: 400;
      p {
        height: px2rem(40);
        line-height: px2rem(40);
      }
    }
  }
}
.page_content {
  background: #f5f5f5;
  padding-top: px2rem(40);
}
.page_1 {
  background-size: contain;
  //   background-attachment: fixed;
  //   background-color: rgb(31, 39, 61);
  background-image: url("~@/assets/image/enterdigiland/content1.png");
  //   width: 100%;
  width: px2rem(1201);
  margin: 0 auto;
  height: px2rem(521);
  position: relative;
  .content {
    .intro_title {
      position: relative;
      margin: 0 0 px2rem(40) px2rem(40);
      padding-left: px2rem(20);
      padding-top: px2rem(40);
      height: px2rem(45);
      font-size: px2rem(32);
      font-weight: 500;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #fff;
      line-height: px2rem(45);

      &::before {
        position: absolute;
        top: px2rem(41);
        left: 0;
        content: "";
        width: px2rem(5);
        height: px2rem(45);
        background: #308971;
        border-radius: px2rem(3);
      }
    }
    .info1 {
      position: absolute;
      top: px2rem(270);
      left: px2rem(12);
      .title {
        height: px2rem(24);
        font-size: px2rem(22);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: px2rem(24);
        margin-bottom: px2rem(10);
        margin-left: px2rem(12);
      }
      .info {
        width: px2rem(110);
        height: px2rem(110);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: px2rem(22);
      }
    }
    .info2 {
      position: absolute;
      top: px2rem(192);
      left: px2rem(183);
      .title {
        height: px2rem(24);
        font-size: px2rem(22);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: px2rem(24);
        margin-bottom: px2rem(10);
        margin-left: px2rem(35);
      }
      .info {
        width: px2rem(156);
        height: px2rem(176);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: px2rem(22);
      }
    }
    .info3 {
      position: absolute;
      top: px2rem(249);
      left: px2rem(398);
      .title {
        height: px2rem(24);
        font-size: px2rem(22);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: px2rem(24);
        margin-bottom: px2rem(10);
        margin-left: px2rem(47);
      }
      .info {
        width: px2rem(182);
        height: px2rem(198);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: px2rem(22);
      }
    }
    .info4 {
      position: absolute;
      top: px2rem(204);
      left: px2rem(638);
      .title {
        height: px2rem(24);
        font-size: px2rem(22);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: px2rem(24);
        margin-bottom: px2rem(10);
        margin-left: px2rem(28);
      }
      .info {
        width: px2rem(144);
        height: px2rem(198);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: px2rem(22);
      }
    }
    .info5 {
      position: absolute;
      top: px2rem(265);
      left: px2rem(842);
      .title {
        height: px2rem(24);
        font-size: px2rem(22);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: px2rem(24);
        margin-bottom: px2rem(10);
        margin-left: px2rem(27);
      }
      .info {
        width: px2rem(144);
        height: px2rem(88);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: px2rem(22);
      }
    }
    .info6 {
      position: absolute;
      top: px2rem(244);
      right: px2rem(13);
      .title {
        height: px2rem(24);
        font-size: px2rem(22);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: px2rem(24);
        margin-bottom: px2rem(10);
        margin-left: px2rem(6);
      }
      .info {
        width: px2rem(144);
        height: px2rem(132);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.45);
        line-height: px2rem(22);
      }
    }
  }
}

.page_2 {
  width: px2rem(1200);
  margin: px2rem(80) auto 0;
  height: px2rem(1544);
  background: #ffffff;
  border-radius: px2rem(2);
  .intro_title {
    height: px2rem(24);
    font-size: px2rem(26);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: px2rem(24);
    padding: px2rem(30) 0 px2rem(29) px2rem(30);
    border-bottom: 1px solid #e9e9e9;
  }
  .banner {
    width: 100%;
    padding: px2rem(29) px2rem(30) 0;
    img {
      width: px2rem(1141);
      height: px2rem(318);
    }
  }
  .page_cardList {
    display: flex;
    // justify-content: space-around;
    margin-top: px2rem(20);
    .page_card {
      width: px2rem(558);
      margin-right: px2rem(21);
      height: px2rem(521);
      background: #ffffff;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #e9e9e9;
      &:first-child {
        margin-left: px2rem(30);
      }
      .page_card_title {
        height: px2rem(60);
        background: #fafafa;
        border-radius: 3px 3px 0px 0px;
        border-bottom: 1px solid #e9e9e9;
        height: px2rem(22);
        font-size: px2rem(20);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: px2rem(22);
        padding: px2rem(19) 0 px2rem(19) px2rem(20);
      }
      .page_card_img {
        padding: px2rem(20);
        img {
          width: px2rem(520);
          height: px2rem(421);
        }
      }
    }
  }
}

.page_3 {
  margin: auto;
  width: px2rem(1195);
  .intro_title {
    position: relative;
    margin: px2rem(79) 0 px2rem(40);
    padding-left: px2rem(20);
    height: px2rem(45);
    font-size: px2rem(32);
    font-weight: 500;
    font-family: PingFangSC-Medium, PingFang SC;
    color: rgba(0, 0, 0, 0.85);
    line-height: px2rem(45);

    &::before {
      position: absolute;
      top: px2rem(1);
      left: 0;
      content: "";
      width: px2rem(5);
      height: px2rem(45);
      background: #308971;
      border-radius: px2rem(3);
    }
  }
  .intro_img {
    img {
      width: px2rem(1195);
      height: px2rem(461);
    }
  }
  .intro_join {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 0px 0px 2px 2px;
    div {
      width: px2rem(597);
      text-align: center;
      padding: px2rem(17) 0;
      height: px2rem(33);
      font-size: px2rem(24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: px2rem(33);
      &:first-child {
        border-right: 1px solid #e9e9e9;
      }
    }
  }
}

.page_4 {
  margin: auto;
  width: px2rem(1195);
  padding-top: px2rem(79);
  border-radius: 0px 0px 2px 2px;
  padding-bottom: px2rem(80);
  .intro_img {
    img {
      width: px2rem(1195);
      height: px2rem(460);
    }
  }
  .intro_info {
    padding: px2rem(17) 0;
    text-align: center;
    height: px2rem(33);
    font-size: px2rem(24);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: px2rem(33);
    background: #fff;
  }
}
</style>
